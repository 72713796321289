div#dafdirectdiv{
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 30px;
  & > form {
    width: 50%;
    margin: 0 auto !important;
    & #notwhatisthis input#dafdirect_dsgtxt {
      width: 100%;
    }
    & #notwhatisthis #amountNextContain .dafdirectInputAmount #dafdirect_amnt {
      width: 100%;
    }
    & #notwhatisthis select#dafprovider {
      width: 100%;
    }
  }
}

form.sli-form {
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
}
.wf-block form.sli-form ul li {
  width: auto;
}
.wf-block form.sli-form ul.sli-cards li {
  width: 55px;
}