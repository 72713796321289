.list-article-content {
  font-family: "Open Sans",Helvetica,Arial,sans-serif;
  color: #494949;
  font-size: 18px;
  a{
    color: #93328e;
    font-size: 32px;
    line-height: 1.5;
    font-weight: 500;
  }
  .entry-excerpt {
    margin-bottom: 15px;
    font-weight: 200;
    a {
      font-size: initial;
    }
  }
  .list-article-meta {
    font-weight: 200;
  }
}
