.wf-block-custom-cta {
  text-align: center;
  h1, h2, h3, h4, h5, h5 {
    font-family: "Muli","Open Sans",Helvetica,Arial,sans-serif;
  }
  font-family: "Muli","Open Sans",Helvetica,Arial,sans-serif;
  .wf-block-appearance {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bg-dark:before {
    background-color: rgba(0,0,0,.5);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
  }
  .col {
    z-index: 2;
  }
  .super-heading, .heading {
    font-weight: 900;
    letter-spacing: 4px;
    width: 100%;
  }
  .super-heading {
    font-size: 18px;
  }
  .heading {
    font-size: 34px;
    text-transform: uppercase;
  }
  .inner-cta-wrapper {
    background-size: cover;
    background-position: center;
  }
  .link-wrapper {
    font-family: Raleway,Helvetica,Arial,sans-serif;
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    flex-direction: row;
    .link-subtitle {
      font-size: 18px;
      margin-bottom: 15px;
      margin-bottom: .9375rem;
      margin-top: 15px;
      margin-top: .9375rem;
    }
    a {
      margin-bottom: auto;
    }
  }
  @media (max-width: 992px) {
    .link-wrapper {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      flex-direction: column;
    }
  }
}
