.tribe-events-tooltip-theme {
  display: none !important;
}
.tribe-events-all-link {
    margin: 0 0 20px;
    color: #494949;
    font-size: 15px;
    font-weight: bold;
}
.tribe-events-header .tribe-events-sub-nav:first-of-type {
  display: none;
}
.wf-hidden {
  display: none;
}
body.tribe-is-responsive #hero .page-banner {
  min-height: 12rem;
  background-position: center;
}
body.tribe-is-responsive.tribe-mobile #hero .page-banner {
  padding-top: 160px !important;
}
h1.tribe-events-single-event-title {
  font-size: 1.7em;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.tribe-events-related-events-title {
  margin-top: 2%;
}
