.wf-block-four-icon-cta {
    color: $white;
    text-align: center;
    font-size: 18px;
    h1, h2, h3, h4, h5, h6 {
        color: $white;
    }
    p {
        color: $white;
    }
    .wf-block-appearance {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .wf-block-appearance > .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .small-heading{
        font-size: 12px;
        display: inline-block;
        position: relative;
        margin-bottom: 13px;
        margin-bottom: .8125rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        @media(min-width: 940px){
            font-size: 14px;
        }
    }
    .large-heading {
        font-size: 30px;
        letter-spacing: 2px;
        font-weight: 800;
        position: relative;
        margin-bottom: 0;
        text-transform: uppercase;
        @media(min-width: 940px){
            font-size: 40px;
            letter-spacing: 3.5px;
        }
    }
    .description {
        font-size: 20px;
        font-size: 1.25rem;
        margin-top: 40px;
        margin-top: 2.5rem;
    }
    .small-heading, .large-heading, .description > p {
        z-index: 100;
    }
    .feature-media {
        text-align: center;
        margin-bottom: 20px;
        .icon-image {
            font-size: 4em;
            position: relative;
            display: inline-block;
            width: 2em;
            height: 2em;
            line-height: 2em;
            vertical-align: middle;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 60%;
                height: 60%;
                margin: 20%;
                pointer-events: none;
            }
        }
        .features-img-background {
            border-radius: 50%;
            margin: 5%;
            height: 90%;
            width: 90%;
            background-color: #c1a01f;
            transition: background-color .5s,height .5s,width .5s,margin .5s;
            transition-timing-function: ease;
            &:hover {
                background-color: #333;
                margin: 0;
                height: 100%;
                width: 100%
            }
        }
    }
}
