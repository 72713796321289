// Lists
ol, ul {
  padding-left: 2rem;
  font-size: 18px;
  /*li::before {
    content: "\2022";
    color: $black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }*/
}

// Buttons
.btn {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-white {
  background-color: $white;
  color: $dark;
  border: 1px solid $dark;

  &:focus, &:hover {
    color: $purple;
    border: 1px solid $purple;
  }
}

.thrive-outline-btn {
  background-color: rgba(255,255,255,.5) !important;
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: #1b3d4c !important;
  color: #1b3d4c !important;
  font-size: 1.0625rem;
  padding: .75rem 1.25rem;
  line-height: 1.333333;
  z-index: 1;
  text-transform: uppercase;
}


// Navbar
.navbar-logo {
  height: 60px;
  width: auto;
}

.navbar-nav {
  margin: 0 -.5rem;
}

// Nav
.nav {
  margin: 0 -.5rem;
}

.nav-item {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  letter-spacing: 1px;
  padding: .25rem .5rem;
}

// Tabs
.nav-tabs {
  .nav-item {
    padding: 0;
  }
}

// Dropdown
.dropdown-menu {
  //padding: $dropdown-padding-y $dropdown-padding-y;
  margin-top: 20px !important;
  padding: 0 !important;
  &.open {
    display: block;
    & > li {
      background-color: #fafafa;
    }
  }
  ul.third-level {
    position: absolute;
    float: left;
    top: 0px;
    left: 100%;
    min-width: 10rem;
    padding: 0;
    font-size: .75rem;
    color: #93328e;
    text-align: left;
    list-style: none;
    background-color: #E9E9E9;
    background-clip: padding-box;
    @media (max-width: 991px){
      border: none;
    }
    border: 1px solid #93328e;
    li {
      background-color: #E9E9E9;
      &:not(:first-of-type) {
        border-top: 1px solid #FFFFFF;
      }
    }
  }
  li {
    & ul.third-level {
      display: none;
    }
    & ul.third-level.open{
      display: block;
    }
  }
  @media (min-width: 992px){
    li {
      & ul.third-level {
        display: none;
      }
      &:hover ul.third-level {
        display: block;
      }
    }
  }
}

.dropdown-item {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  letter-spacing: 1px;

}

.dropdown-item-wrap {
  &:not(:first-of-type) {
    border-top: 1px solid #e9e9e9;
  }
}


// Icons
.icon-utility {
  width: auto;
  height: 20px;
}

.icon-footer {
  width: auto;
  height: 32px;
}

// Logos
.logo-footer {
  width: auto;
  height: 60px;
}


// Universal custom styled elements
.full-size-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.inner-container {
  position: relative;
  padding: 70px 0;
  padding: 4.375rem 0;
}

// Page Banner
.page-banner {
  color: $white;
  position: relative;
  text-align: center;
  padding: 8% 0;

  &__center {
    text-align: center;
  }
  &__left {
    text-align: left;
  }
  &__right {
    text-align: right;
  }
  &__top {
    padding: 3% 0 13%;
  }
  &__bottom {
    padding: 13% 0 3%;
  }

  .h1 {
    font-family: Cochin,"serif";
    font-size: 7rem;
    font-weight: 100;
    z-index: 2;
    position: relative;
    text-shadow: 0 4px 6px rgba(0,0,0,.8);
    @media (max-width: 991px){
      font-size: 5rem;
    }
    @media (max-width: 460px){
      font-size: 3rem;
    }
  }
}

// generic 'a' tag styling
a {
  color: #93328E;
}

table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #e9e9e9;
  border-collapse: collapse;
  border-spacing: 0;
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  td {
    border: 1px solid #e9e9e9;
    line-height: 1.42857;
    padding: 5px;
    vertical-align: middle;
    text-align: center;
    font-family: "Open Sans",Helvetica,Arial,sans-serif;
  }
}

p {
  color: #494949;
  font-size: 18px;
  margin-bottom: 15px;
  margin-bottom: .9375rem;
  margin-top: 0;
}

body {
  font-family: "Open Sans",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.7;
  color: #494949;
}

#footer {
  p {
    color: $white;
  }
}

.thrive-btn-outline {
  background: rgba(255,255,255,0) !important;
  transition: background .2s;
  &:hover {
    background: rgba(255,255,255,.4) !important;
  }
}

.page-banner .description {
  z-index: 2;
  position: relative;
  p{
  font-size: 22px;
  font-family: 'Cochin','serif';
  letter-spacing: .4px;
  text-shadow: 0 2px 8px rgba(0,0,0,.15);
  color: #fff;
  text-align: center;
  font-weight: 100;
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  @media(min-width: 992px){
    font-size: 2rem;
  }
  }
}

// Major Nav Updates
.sub-menu-trigger {
  display: none;
  & em {
    transition: all .2s;
    -webkit-transform:rotate(-0deg);
    -moz-transform:rotate(-0deg);
    -o-transform:rotate(-0deg);
    transform:rotate(-0deg);
    ms-filter:"progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  }
  &.down em {
    -webkit-transform:rotate(-180deg);
    -moz-transform:rotate(-180deg);
    -o-transform:rotate(-180deg);
    transform:rotate(-180deg);
    ms-filter:"progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  }
}

.dropdown-menu {
  &.second-level li:not(:first-of-type) {
    border-top: 1px solid $gray-300;
  }
}

@media(max-width: 991px){
  .nav-item {
    padding: 8px 0 8px 2% !important;
    a:not(.btn) {
      padding: 6px 0px;
    }
    .nav-link {
      width: calc(100% - 33px);
    }
  }

  .sub-menu-trigger {
    display: block;
    color: #999;
    border-left: 1px solid #e9e9e9;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    text-align: center;
    padding: 6px 32px 6px 16px;
    z-index: 10;
    font-size: 22px;
    &.down {
      padding-bottom: 8px !important;
    }
  }
  .dropdown-menu {
    text-transform: uppercase!important;
    margin-top: 10px !important;
    border: none;
    border-top: 1px solid #e9e9e9;
    .dropdown-item {
      width: calc(100% - 60px);
      &.no-children {
        width: calc(100% - 20px);
      }
    }
    li {
      position: relative;
      padding: 8px 16px;
      border-bottom: 1px solid #e9e9e9;
    }
  }
  .navbar-collapse {
    background-color: $white;
    position: absolute;
    top: 78px;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  .navbar {
    position: static;
  }
  .navbar-dark .navbar-nav .show>.nav-link {
    color: $purple;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: $purple;
    &:hover {
      color: $purple;
    }
    &:after {
      content: none;
    }
  }
  .navbar-dark .navbar-nav .nav-item {
    width: 100%;
    background-color: #FFFFFF;
    &:not(:first-of-type) {
      border-top: 1px solid #e9e9e9;
    }
    &:last-of-type {
      border-bottom: 1px solid #e9e9e9;
    }
    a {
      padding-left: 3%;
    }
  }
  .navbar {
    padding-bottom: 0;
  }
  .dropdown-menu ul.third-level {
    position: relative;
    float: none;
    left: 0;
    margin-left: -3%;
    margin-right: -3%;
    li a{
      padding-left: 7% !important;
      width: 100%;
    }
  }
  #header-navigation-collapse > .nav-wrapper + div {
    & > div {
      padding-top: 0px !important;
    }
    visibility: hidden;
    .utility-navigation li {
      width: 100%;
      border-bottom: 1px solid #e9e9e9;
    }
    .utility-navigation > ul li:not(:last-of-type) {
      display: none;
    }
    .utility-navigation > ul > li:last-of-type {
      padding-top: 0px !important;
      visibility: visible;
      ul {
        display: block;
        visibility: visible;
        & li {
          visibility: visible;
          display: block;
          padding-left: 4% !important;
          a:not(.btn) {
            color: #93328e;
            border: none;
          }
        }
        & li:last-of-type a:not(.btn) {
          color: black;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}

.dropdown-item.pb-open-nav {
  padding-bottom: 16px !important;
}
.nav-link.pb-open-nav {
  padding-bottom: 4px !important;
}
.nav-item.pb-0 {
  padding-bottom: 0px !important;
}

.wf-block-content-row {
  blockquote {
    border-color: $purple;
    padding: 30px;
    position: relative;
    background: #f8f9f9;
    border-left: 3px solid $purple;
    font-style: italic;
    p {
      margin: 0;
    }
  }
}

#footer address a {
  color: $white;
}

#tribe-bar-filters {
  .tribe-bar-submit {
    width: 100%;
    .tribe-events-button {
      width: 25% !important;
    }
  }
}

.border-bottom-none {
  border-bottom: none !important;
}

@media (min-width: 992px) {
  ul.dropdown-menu.show > li {
    position: relative;
  }
}

#noEventsModal .modal-title {
  font-size: 25px;
  color: $black;
}

.tribe-events-notices {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  color: $black;
  font-family: "Raleway","RalewayBold",Helvetica,Arial,sans-serif;
}

// UL updates
.wf-block ul {
  list-style-type: none;
  li {
    display: inline-block;
    width: 100%;
  }
  li:before {
    display: block;
    content: "\2022";
    color: $black;
    background-size: 4px 4px;
    height: 4px;
    width: 4px;
    position: absolute;
    left: 35px;
    margin-left: -10px;
  }
}

.page-heading {
  h1 {
    font-size: 2rem;
    font-family: "Raleway",Helvetica,Arial,sans-serif;
  }
}

.entry-title {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
}

.entry-descrip {
  padding: 20px 0;
  text-align: center;
}

.faq-wrapper {
  h3 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    font-family: "Raleway",Helvetica,Arial,sans-serif;
  }
}

.wf-block-rich-text {
  h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway",Helvetica,Arial,sans-serif;
  }
  .donate-page-content {
    h4 {
      font-size: 17px;
      font-size: 1.0625rem;
      margin-bottom: 12px;
    }
  }
}

.tribe-events-page-title {
  margin-top: 3rem;
}

.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
  padding-top: 56.0714%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content-row-descrip {
  p {
    color: inherit !important;
  }
}
a.non-crawlable {
  display: block;
}
a.hiddenMail {
  display: none !important;
}