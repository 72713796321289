img.alignright{
    position: relative;
    float: right;
    clear: none;
    left: -3px;
    right: -3px;
    margin-left: .5rem;
    width: auto;
}
img.alignleft {
    position: relative;
    float: left;
    clear: none;
    left: -3px;
    right: -3px;
    margin-right: .5rem;
    width: auto;
}
figure.alignright {
    position: relative;
    float: right;
    left: -3px;
    right: -3px;
    margin-left: .5rem;
}
figure.alignleft {
    position: relative;
    float: left;
    left: -3px;
    right: -3px;
    margin-right: .5rem;
}

ul.pagination {
    li {
        width: auto;
        &:before {
            content: '';
        }
    }
}

.wf-block-post-teasers {
    .list-article-thumb {
        float: left;
        margin-right: 30px;
    }
    .list-article-content {
        float: none;
        overflow: hidden;
    }
}