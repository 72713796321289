.wf-block-home-gallery {
  h1, h2, h3, h4, h5, h6 {
    color: $white;
  }
  .wf-block-appearance {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .wf-block-appearance > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .section-title {
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    font-size: 30px;
  }
  .section-title, .section-subtitle {
    z-index: 100;
  }
  .section-subtitle {
    font-weight: 500;
  }
  @media (min-width: 940px){
    .section-title {
      font-size: 40px;
      letter-spacing: 3.5px;
    }
  }
}
