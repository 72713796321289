#shine-newsletter {
    .wf-block-appearance {
        padding-top: 0 !important;
    }
}

#social-rec-newsletter {
    .wf-block-appearance {
        padding-top: 0 !important;
    }
}