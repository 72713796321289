/** Styles for the alert banner **/

#alert-banner { 
    width: 100%;
    display: none;
    position: relative;
    z-index: 999;
    .alert-banner-inner{
        position: absolute;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        .alert-text { 
            margin-bottom: 0px;
            line-height: 32px;
            color: #fff;
            font-size: 17px;
            font-weight: 700;
            @media(max-width: 768px) {
                font-size: 14px;
            }
        }
        #banner_dismiss {     
            font-family: "Raleway", Helvetica, Arial, sans-serif;
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
            text-decoration: none;
            zoom: 1;
            color: #FFFFFF;
            margin-top: 8px;
            i {
                pointer-events: none;
            }
        }
        a { 
            color: #fff;
            text-decoration: underline;
        }
        a.btn-alert {
            color: $white;
            background-color: $purple;
            font-size: .75rem;
            text-decoration: none;
            font-weight: 800;
        }
        .alert-banner-text-wrap {
            padding-right: 20px;
            padding-left: 20px;
        }
    }
}

