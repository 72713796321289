// 'Fancy' Counter stylesheet
.wf-block-fancy-counter{
  .section-title{
    text-transform: uppercase;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 3.5px;
  }
  .counter_item {
      text-align: center;
      margin-bottom: 35px;
      margin-bottom: 2.1875rem;
      .counter_number {
          font-size: 50px;
          font-size: 3.125rem;
          font-weight: 800;
          color: #03c4eb;
          line-height: 1;
          font-family: serif,sans-serif;
          text-transform: uppercase;
          padding: 16px 0;
          span {
              color: #1b3d4c;
          }
      }
      .counter_title {
          font-size: 18px;
          font-size: 1.125rem;
      }
  }
}
