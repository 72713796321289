.content-row {
  &-image-full {
    min-height: 300px;
    background-size: cover;
    background-position: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_full {
    .content-row-content .container {
      padding-top: 5em;
      padding-bottom: 5em;
    }
  }
}
@include media-breakpoint-up(lg) {
  .content-row_full {
    .content-row-content .container {
      padding-top: 10em;
      padding-bottom: 10em;
    }
  }
}

.wf-block-content-row {
  .wf-block-appearance {
    //padding-top: 0 !important;
    //padding-bottom: 0 !important;
    .container-fluid {
      padding-right: 0 !important;
      padding-left: 0 !important;
      > .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway",Helvetica,Arial,sans-serif;
  }
  h2 {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .content-row-image {
    padding-left: 0;
    min-height: 400px;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  .content-row-image-container img {
    width: 100%;
    height: auto;
  }
  .constrained-width {
    padding-right: 20%;
    padding-left: 20%;
    @media(max-width: 768px){
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.volunteer-content-row {
  h5 {
    margin-top: 2rem;
  }
}