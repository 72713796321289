.wf-block-fancy-carousel{
    .wf-block-appearance {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .wf-block-appearance > .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .carousel-content {
        pointer-events: none;
        position: absolute;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
        @media(max-width: 992px){
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
            top: 0;
        }

        &__center {
            right: 15%;
            left: 15%;
            top: 50%;
            transform: translateY(-50%);
        }
        &__top {
            right: 15%;
            left: 15%;
            top: 0;
        }
        &__bottom {
            right: 15%;
            left: 15%;
            bottom: 0;
        }
        &__left {
            right: 25%;
            left: 5%;
            top: 50%;
            transform: translateY(-50%);
            .hero-large-text {
                text-align: left;
            }
            .hero-button-row {
                margin-left: 0;
                justify-content: flex-start;
            }
        }
        &__right {
            right: 5%;
            left: 25%;
            top: 50%;
            transform: translateY(-50%);

            .hero-large-text {
                text-align: right;
            }
            .hero-button-row {
                margin-right: 0;
                justify-content: flex-end;
            }
        }
    }
    .carousel-item {
        height: 30rem;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &__bg-center {
            img {
                object-position: center;
            }
        }
        &__bg-left {
            img {
                object-position: left;
            }
        }
        &__bg-right {
            img {
                object-position: right;
            }
        }
        &__bg-top {
            img {
                object-position: top;
            }
        }
        &__bg-bottom {
            img {
                object-position: bottom;
            }
        }
    }
    .hero-large-text{
        font-size: 7rem;
        font-family: 'Cochin', 'serif';
        text-shadow: 0 4px 6px rgba(0, 0, 0, 0.8);
        color: #fff;
        text-align: center;
        font-weight: 100;
        margin-bottom: 30px;
        margin-bottom: 1.875rem;
        @media (max-width: 768px) {
            margin-bottom: 0;
            font-size: 4rem;
        }
        @media (max-width: 464px) {
          font-size: 3rem;
        }
    }
    .hero-button-row {
        margin-left: 15%;
        margin-right: 15%;
        justify-content: center;
        @media (max-width: 768px){
          margin-left: 0;
          margin-right: 0;
        }
        a{
            padding: 5px;
            border: none;
            border-radius: 2px;
            transition-delay: 1s;
            transition: all .5s linear;
            text-transform: uppercase;
            font-size: 17px;
            font-size: 1rem;
            min-width: 160px;
            pointer-events: all;
            @media (max-width: 768px) {
                margin-top: 0;
            }
            &:first-of-type {
                margin-right: 20px;
                @media (max-width: 768px) {
                    margin-right: 5px;
                }
                @media (max-width: 464px) {
                  margin-right: 0px;
                  margin-bottom: 8px;
                }
            }
        }
    }
}

#tribe-events-pg-template {
    a.tribe-events-gcal {
        margin-right: 0;
    }
    @media (max-width: 1200px) {
        margin-right: 15%;
        margin-left: 15%;
    }
    @media (max-width: 992px) {
        margin-right: 5%;
        margin-left: 5%;
    }
    @media (max-width: 992px) {
        margin-right: auto;
        margin-left: auto;
    }
}