.accordion > .card .card-header > [data-toggle="collapse"] {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: "\002b";
    margin-left: 0.5rem;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    font-family: $font-family-monospace;
  }

  &:not(.collapsed):after {
    content: "\2212";
  }
}
