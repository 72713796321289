#side-cta {
    display: none;
    position: fixed;
    transform: rotate(270deg);
    z-index: 1050;
    left: 0;
    top: 45vh;
    margin-left: -112px;

    .side-cta-text {
        padding: .5rem;

        a {
            color: $white;
            padding-left: 1.75rem;
            padding-right: 1.75rem;
            font-size: 1.25rem;
        }
    }

    @include media-breakpoint-up(lg) {
        display: block;
    }
}