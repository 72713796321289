// Accordion which appears as tabs on larger responsive ranges

// Smallest breakpoint where the accordion appears as tabs
$accordion-tabs-breakpoint: "md";

// .accordion-tabs-toggles uses nav-tabs styles
.accordion-tabs-toggles {
  display: none;

  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    display: flex;
    margin: 0;
  }

  .nav-link:not(.collapsed) {
    background-color: $nav-tabs-link-active-bg;
    color: $nav-tabs-link-active-color;
    border-color: $nav-tabs-link-active-border-color;
    border-bottom-color: transparent;
  }
}

.accordion-tabs-collapses {
  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    border: $nav-tabs-border-width solid $nav-tabs-border-color;
    border-top: 0;
  }
}

.accordion-tabs-card {
  margin-bottom: 0;

  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    border: 0 !important;
  }
}

.accordion-tabs-card-header {
  margin-bottom: -1px;

  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    display: none;
  }
}

.accordion-tabs-collapse.collapsing {
  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    transition-duration: 0.001s; // HACK: Hide collapsing height animation while respecting transition-dependent Bootstrap JS
  }
}