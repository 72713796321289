#header {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 1000;
    ~ main {
        padding-top: 120px;
        @media(max-width: 992px){
            padding-top: 78px;
        }
    }
}
.social-nav a {
    line-height: 0;
    font-size: 0;
    color: transparent !important;
}