
@media (max-width: 1200px) {
    #tribe-events-pg-template {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    #tribe-events-pg-template, .tribe-events-pg-template {
        max-width: inherit !important;
    }
}

.at-below-post.addthis_tool {
    margin-top: 20px;
}
h2.sub-heading {
    color: $white;
    font-family: Cochin,"serif";
    font-size: 4rem;
    font-weight: 100;
    z-index: 2;
    position: relative;
    text-shadow: 0 4px 6px rgba(0,0,0,.8);
    @media (max-width: 991px){
      font-size: 3rem;
    }
    @media (max-width: 460px){
      font-size: 2rem;
    }
}