.goog-te-ftab-float {
    width: 108px;
    height: 34px;
    opacity: 0;
    transition: all .2s;
}

iframe.goog-te-ftab-frame {
    width: 108px;
    height: 34px;
    border-radius: 3px 3px 0 0;
    visibility: visible;
}