.accordion-wrapper, .accordion-wrapper *{
    transition: all .4s;
}
.accordion-wrapper .btn-primary {
    border: none !important;
}
.accordion-title {
    font-size: 24px;
    color: #FFF;
    line-height: 32px;
    padding: 1.2rem 0 1.2rem 36px;
}

.accordion-title:before {
    content: '+';
    left: 12px;
    font-size: 24px;
    position: absolute;
    transition: all .2s;
}
.accordion-controller.opened .accordion-title:before, .inner-controller.opened:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.accordion-controller {
    position: relative;
}
.accordion-controller.outer-controller {
    padding: 2rem 0;
    color: #FFF;
}

.accordion-title.purple-background {
    background-color: #93328E;
}

.accordion-title.gold-background {
    background-color: #C1A01F;
}

.inner-category {
    padding: 10px !important;
    margin-left: 8% !important;
}
.sub.inner-category {
    margin-left: 12% !important
}
.inner-category.active {
    background-color: #D1D1D1;
}

.inner-category .inner-category-title {
    font-size: 28px;
    color: #000;
    line-height: 32px;
    padding-top: 1rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

.sub.inner-category .inner-category-title {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 0;
}

.inner-category .inner-category-intro {
    font-size: 18px;
    color: #494949;
}

.accordion-intro, .accordion-content.inner-category {
    padding: 2rem 0;
    font-size: 18px;
    color: #494949;
}

.accordion-content {
    height: 100%;
    opacity: 1;
    overflow: hidden;
    visibility: visible;
    position: relative;
}

.accordion-content.closed {
    height: 0px;
    visibility: hidden;
    opacity: 0;
    /*overflow-y: hidden;*/
}

.inner-category .accordion-btn-wrap {
    padding: 1rem 0;
}